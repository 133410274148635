<template>
  <div class="row justify-content-center" style="padding-inline: 5px">
    <div class="col-md-12" v-if="!createSenderID">
      <el-form
        :model="formData"
        :rules="rules"
        ref="ruleForm"
        @submit.prevent.native="checkEnter"
        class="form-container"
      >
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="form_input_container" id="__create">
              <!-- :class="[corporate ? '_select' : '___total_width']" -->
              <div class="_select">
                <el-form-item
                  :label="$t('dashboard.overview.add_sender_id')"
                  prop="sender_id"
                >
                  <el-select
                    style="width: 100%"
                    @change="handleInputChange($event, 'sender_id')"
                    v-model="formData.sender_id"
                    filterable
                    :placeholder="$t('dashboard.send_message.select_sender_id')"
                  >
                    <el-option
                      v-for="(senderid, key) in senderIDs"
                      :key="key"
                      :label="senderid.sender_id"
                      :value="senderid.id"
                      :disabled="senderid.status != 1"
                      class="notranslate"
                    >
                      <div
                        style="width: 100%"
                        class="d-flex justify-content-between"
                      >
                        <div>{{ senderid.sender_id }}</div>
                        <img
                          v-if="senderid.status == 1"
                          style="margin-left: 2em; width: 18px; height: 18px"
                          src="@/assets/Shield Done.svg"
                          alt="shield icon"
                        />
                        <img
                          v-if="senderid.status == 0"
                          style="margin-left: 2em; width: 18px; height: 18px"
                          src="@/assets/Shield_pending.svg"
                          alt="shield icon"
                        />
                        <img
                          v-if="senderid.status == 2"
                          style="margin-left: 2em; width: 18px; height: 18px"
                          src="@/assets/shield_declined.svg"
                          alt="shield icon"
                        />
                        <img
                          v-if="senderid.status == 3"
                          style="margin-left: 2em; width: 18px; height: 18px"
                          src="@/assets/shield_on_hold.svg"
                          alt="shield icon"
                        />
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="button">
                <el-button
                  style="width: fit-content"
                  type="primary"
                  @click="createSenderID = true"
                  >{{
                    $t(
                      "dashboard.send_message.message_templates.form.message.create_sender_id"
                    )
                  }}</el-button
                >
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form_input_container">
              <el-form-item
                :label="
                  $t(
                    'dashboard.send_message.message_templates.form.title.campaign_title'
                  )
                "
                prop="campaign_name"
              >
                <el-input
                  type="text"
                  autocomplete="off"
                  :placeholder="
                    $t(
                      'dashboard.send_message.message_templates.form.title.placeholder_campaign'
                    )
                  "
                  @change="handleInputChange($event, 'campaign_name')"
                  v-model="formData.campaign_name"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <!-- <div class="col-md-12">
            <div class="form_input_container">
              <el-form-item label="Select Route" prop="route_name">
                <selectRoute v-model.lazy="formData.route_name" showHelp />
              </el-form-item>
            </div>
          </div> -->
          <div class="col-md-12">
            <p style="margin: 0px">
              <el-form-item
                style="margin: 0px"
                :label="
                  $t(
                    'dashboard.send_message.message_templates.form.content.label'
                  )
                "
                required
                :show-message="false"
              >
              </el-form-item>
            </p>
            <div class="content_style" v-if="checkIfGroup">
              <div @click="insertText('[fname]')">
                [
                {{
                  $t(
                    "dashboard.send_message.message_templates.form.content.first_name"
                  )
                }}]
              </div>
              <div @click="insertText('[lname]')">
                [{{
                  $t(
                    "dashboard.send_message.message_templates.form.content.last_name"
                  )
                }}]
              </div>
              <div @click="insertText('[email]')">
                [{{
                  $t(
                    "dashboard.send_message.message_templates.form.content.email"
                  )
                }}]
              </div>
              <div @click="insertText('[phone]')">
                [{{
                  $t(
                    "dashboard.send_message.message_templates.form.content.phone"
                  )
                }}]
              </div>
              <div @click="insertText('[dob]')">
                [{{
                  $t(
                    "dashboard.send_message.message_templates.form.content.dob"
                  )
                }}]
              </div>
            </div>
            <small v-if="checkIfExcel == 'success'" class="small-header-text">{{
              $t(
                "dashboard.send_message.message_templates.form.content.add_column"
              )
            }}</small>

            <div
              class="__message__form__text contains-custom-headers notranste"
              v-if="checkIfExcel == 'success'"
            >
              <button
                class="notranslate"
                v-for="(header, key) in payloadData.headers"
                :key="key"
                @click.prevent="insertText(`[${header}]`)"
              >
                {{ `[${header}]` }}
              </button>
            </div>
            <div class="form_input_container">
              <el-form-item prop="message">
                <el-input
                  type="textarea"
                  :rows="4"
                  autocomplete="off"
                  :placeholder="
                    $t(
                      'dashboard.send_message.message_templates.form.message.placeholder'
                    )
                  "
                  ref="message"
                  v-model="formatASCII"
                  @change="handleInputChange($event, 'message')"
                  id="simpleTextArea"
                >
                </el-input>
              </el-form-item>
            </div>

            <div class="d-flex">
              <p class="text_description">
                {{ formData.message.length }}
                {{
                  $t(
                    "dashboard.send_message.message_templates.form.message.characters_entered"
                  )
                }}
              </p>
              <p class="text_description pl-4" v-if="findNumberOfRecipient">
                {{ findNumberOfRecipient }}
                {{
                  $t(
                    "dashboard.send_message.message_templates.form.message.per_recipient"
                  )
                }}
              </p>
              <div class="text_description pl-4" v-if="findNumberOfRecipient">
                {{
                  $t(
                    "dashboard.send_message.message_templates.form.message.encoding_type"
                  )
                }}

                <span
                  v-if="
                    getEncoding == 'GSM_7BIT' || getEncoding == 'GSM_7BIT_EX'
                  "
                  style="color: #36d962"
                  >GSM_7BIT</span
                >
                <span v-else style="color: red">{{ getEncoding }}</span>
              </div>
            </div>
            <div class="use-gsm" style="padding-bottom: 2rem">
              <div class="info-gsm-only">
                <el-button
                  @click="showGSM_Only_Info = true"
                  size="small"
                  :title="
                    $t(
                      'dashboard.send_message.message_templates.form.message.learn_more'
                    )
                  "
                  class="info-gsm-button"
                >
                  <el-icon class="info-icon" name="info"></el-icon
                ></el-button>
              </div>
              <div class="check-gsm-only">
                <el-checkbox
                  :label="
                    $t(
                      'dashboard.send_message.message_templates.form.message.gsm_only'
                    )
                  "
                  v-model="useGSMOnly"
                ></el-checkbox>
              </div>
              <!-- AI Modal -->
              <div class="info-gsm-only">
                <AIStars
                  :campaign="formData"
                  @ai-closed="handleAIClosed"
                  :before-open="handleAIBeforeOpen"
                />
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <div class="row justify-content-center" v-if="createSenderID">
      <div class="col-md-12">
        <el-form
          :model="formData"
          :rules="rules"
          ref="ruleForm"
          @submit.prevent.native="checkEnter"
        >
          <div class="row px-3">
            <div class="col-md-12">
              <div class="form_input_container">
                <el-form-item
                  :label="$t('dashboard.sender_id.label')"
                  prop="sender"
                >
                  <el-input
                    type="text"
                    autocomplete="off"
                    :placeholder="$t('dashboard.sender_id.placeholder')"
                    v-model="formData.sender"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form_input_container">
                <el-form-item
                  :label="$t('dashboard.sender_id.purpose')"
                  prop="purpose"
                >
                  <el-input
                    type="textarea"
                    :rows="4"
                    autocomplete="off"
                    :placeholder="$t('dashboard.sender_id.placeholder_purpose')"
                    v-model="formData.purpose"
                  ></el-input>
                  <template #label></template>
                </el-form-item>
              </div>
            </div>
            <div class="col-md-12" v-if="openSenderIDError">
              <Alert :message="openSenderIDError" :type="type" />
            </div>
            <div class="col-md-12 d-flex justify-content-between mb-5 mt-3">
              <button
                class="cancel mr-2 notranslate"
                @click="createSenderID = false"
              >
                {{ $t("misc.button.cancel") }}
              </button>
              <el-button
                style="padding-top: 1.1em; padding-bottom: 1.1em"
                type="primary"
                :loading="loading"
                @click="submit('ruleForm', 'createSenderID')"
                >create</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <div class="col-md-12" v-if="errors">
      <Alert :message="errors" :type="type" />
    </div>
    <div
      class="mt-3 mb-4 col-md-12 d-flex justify-content-between"
      style="width: 100%"
      v-if="!createSenderID"
    >
      <button class="___back__btn" @click="cancelModal">
        {{ $t("misc.button.back") }}
      </button>
      <button
        v-if="!isEdit"
        class="cancel mr-1"
        @click="sendMessageTo('ruleForm', 'schedule')"
      >
        {{ $t("dashboard.send_message.scheduled_messages.schedule_message") }}
      </button>
      <el-button
        :loading="loading"
        v-if="!isEdit && checkMessageScheduled == false"
        style="padding-right: 2em; color: #fff; padding-left: 2em"
        type="primary"
        @click="sendMessageTo('ruleForm', 'send')"
        >{{ $t("misc.button.next") }}
      </el-button>
      <el-button
        :loading="loading"
        v-if="isEdit"
        style="padding-right: 2em; color: #fff; padding-left: 2em"
        type="primary"
        @click="sendMessageTo('ruleForm', 'edit')"
        >{{ $t("misc.button.edit") }}
      </el-button>
    </div>
    <el-dialog
      :visible.sync="showGSM_Only_Info"
      :title="$t('dashboard.send_message.message_templates.notes.title')"
      class="main-modal"
      :modal="false"
      :top="'3rem'"
      :width="dynamicModalWidth"
    >
      <div class="modal-header">
        <h4 class="modal-title">
          {{ $t("dashboard.send_message.message_templates.notes.title") }}
        </h4>
      </div>
      <div class="modal-body">
        <div class="long-form-sms">
          <h5>
            <b>{{
              $t(
                "dashboard.send_message.message_templates.notes.long_sms_messages"
              )
            }}</b>
          </h5>
          <p>
            {{
              $t("dashboard.send_message.message_templates.notes.exceed_160")
            }}
          </p>
          <br />
          <p>
            {{ $t("dashboard.send_message.message_templates.notes.see_below") }}
          </p>
          <ul>
            <li>
              {{
                $t(
                  "dashboard.send_message.message_templates.notes.standard_gsm.character_counts.2_message_parts"
                )
              }}
            </li>
            <li>
              {{
                $t(
                  "dashboard.send_message.message_templates.notes.standard_gsm.character_counts.3_message_parts"
                )
              }}
            </li>
            <li>
              {{
                $t(
                  "dashboard.send_message.message_templates.notes.standard_gsm.character_counts.4_message_parts"
                )
              }}
            </li>
          </ul>
        </div>
        <div class="utf-16">
          <h5>
            <b>{{
              $t("dashboard.send_message.message_templates.notes.unicode.title")
            }}</b>
          </h5>
          <p>
            {{
              $t(
                "dashboard.send_message.message_templates.notes.unicode.description"
              )
            }}
          </p>
          <br />
          <p>
            {{
              $t("dashboard.send_message.message_templates.notes.unicode.note")
            }}
          </p>
          <br />
          <p>
            {{
              $t(
                "dashboard.send_message.message_templates.notes.unicode.see_below"
              )
            }}
          </p>
          <ul>
            <li>
              {{
                $t(
                  "dashboard.send_message.message_templates.notes.unicode.character_counts.2_message_segments"
                )
              }}
            </li>
            <li>
              {{
                $t(
                  "dashboard.send_message.message_templates.notes.unicode.character_counts.3_message_segments"
                )
              }}
            </li>
            <li>
              {{
                $t(
                  "dashboard.send_message.message_templates.notes.unicode.character_counts.4_message_segments"
                )
              }}
            </li>
          </ul>
        </div>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="showGSM_Only_Info = false"
          >OK</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import store from "@/state/store.js";
  import Storage from "@/services/storage.js";
  import get_length_and_segment from "@/helpers/character_counter";
  import _ from "lodash";
  import SendToGroup from "./sendToGroup.vue";
  import ASCIIFolder from "fold-to-ascii";
  import selectRoute from "./global/selectRoute.vue";
  import AIStars from "./ai/aiStars.vue";
  export default {
    name: "send-simple-message",
    components: {
      selectRoute,
      AIStars,
    },
    props: {
      payloadData: {
        type: Object,
        required: true,
      },
      // SendToGroup
    },
    data() {
      return {
        type: "alert-danger",
        messages: "",
        success: "",
        errors: "",
        modalType: "",
        loading: false,
        rules: {
          sender_id: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
          campaign_name: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
          message: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
            {
              max: 459,
              message: this.$t(
                "dashboard.send_message.message_templates.form.message.reduce_chars"
              ),
              trigger: ["change", "blur"],
            },
          ],
          sender: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
            {
              max: 11,
              message: this.$t("dashboard.sender_id.min"),
              trigger: ["change", "blur"],
            },
          ],
          purpose: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
          route_name: [
            {
              required: false,
              message: this.$t("misc.form.required"),
              trigger: "blur",
            },
          ],
        },
        formData: {
          campaign_name: "",
          message: "",
          sender_id: "",
          perMessage: 0,
          campaign_id: "",
          purpose: "",
          sender: "",
          headers: [],
          header: "",
          messageWithTitle: "",
          route_name: "",
        },
        sendMessage: {},
        sendMessageRules: {},
        selectedKey: "",
        isEdit: false,
        selectedKey: "",
        createSenderID: false,
        openSenderIDError: "",
        encodingType: "",
        groupOnly: false,
        useGSMOnly: true,
        showGSM_Only_Info: false,
      };
    },
    mounted() {
      let editedData = store.getters["uistate/contactForm"];
      const getFormData = store.getters["modal/getFormData"];
      if (getFormData.campaign_name) {
        this.formData = {
          campaign_name: getFormData.campaign_name,
          message: getFormData.message,
          sender_id: getFormData.sender_id,
          campaign_id: getFormData.campaign_id,
        };
      }
      if (editedData.isEdit) {
        this.selectedKey = editedData.editData.selectedKey;
        this.isEdit = true;
      }
    },
    computed: {
      corporate() {
        const storage = new Storage();
        return storage.getSavedState("isCorporate");
      },
      messageForm() {
        return store.getters["uistate/contactForm"];
      },
      senderIDs() {
        return store.getters["senderid/allSenderIDs"];
      },

      findNumberOfRecipient() {
        let { message } = this.formData;
        if (message.length == 0) {
          return 0;
        }
        let { messages, encoding } = get_length_and_segment(message);
        this.formData.perMessage = messages;
        this.formData.encoding = encoding;
        this.encodingType = encoding;
        return messages;
      },
      checkMessageScheduled() {
        return store.state.uistate.isScheduledType;
      },
      getEncoding() {
        return this.encodingType;
      },
      checkIfExcel() {
        return this.payloadData.excel_contacts?.status;
      },

      checkIfGroup() {
        let isGroup = store.getters["modal/messageAction"];
        if (isGroup == "group") {
          return true;
        }
      },

      formatASCII: {
        get() {
          return this.formData.message;
        },
        set(value) {
          if (this.useGSMOnly) {
            this.formData.message = ASCIIFolder.foldReplacing(value);
          } else {
            this.formData.message = value;
          }
        },
      },
      dynamicModalWidth() {
        if (screen.width < 768) {
          return "98%";
        } else {
          return "50%";
        }
      },
    },
    methods: {
      async handleAIBeforeOpen() {
        const response = await this.$refs.ruleForm.validate();
        if (typeof response === "boolean") {
          return true;
        }
        return false;
      },

      handleAIClosed($event) {
        if ($event) {
          if ($event !== this.formData.message) {
            this.formData.message = $event;
            this.sendMessageTo("ruleForm", "send");
          }
        }
      },
      handleInputChange(value, key) {
        store.dispatch("modal/updateSingleField", {
          key,
          value,
        });
      },
      insertText(data) {
        // insert at cursor position
        var textarea = document.getElementById("simpleTextArea");
        var strPos = 0;
        var br =
          textarea.selectionStart || textarea.selectionStart == "0"
            ? "ff"
            : document.selection
            ? "ie"
            : false;
        if (br == "ie") {
          textarea.focus();
          var range = document.selection.createRange();
          range.moveStart("character", -textarea.value.length);
          strPos = range.text.length;
        } else if (br == "ff") strPos = textarea.selectionStart;

        var front = textarea.value.substring(0, strPos);
        var back = textarea.value.substring(strPos, textarea.value.length);
        this.formData.message = front + data + back;
        strPos = strPos + data.length;
        if (br == "ie") {
          textarea.focus();
          var range = document.selection.createRange();
          range.moveStart("character", -textarea.value.length);
          range.moveStart("character", strPos);
          range.moveEnd("character", 0);
          range.select();
        } else if (br == "ff") {
          textarea.selectionStart = strPos;
          textarea.selectionEnd = strPos;
          textarea.focus();
        }

        this.$refs.message.focus();
      },

      submit(formName, type) {
        this.loading = true;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (type == "createSenderID") {
              return this.createNewSenderID();
            }
          } else {
            this.loading = false;
            return false;
          }
        });
      },
      createNewSenderID() {
        this.openSenderIDError = "";
        this.loading = true;
        let payload = {
          sender_id: this.formData.sender,
          purpose: this.formData.purpose,
        };
        store
          .dispatch("senderid/registerSenderID", payload)
          .then((response) => {
            const sender = response.data.data;
            if (sender.status == 1) {
              this.formData.sender_id = sender.id;
            }
            if (response) {
              this.loading = false;
              this.createSenderID = false;
            }
            if (response.data.data.status == 0) {
              this.$notify({
                title: this.$t("misc.toast.alert"),
                message: this.$t("dashboard.sender_id.contact_admin"),
                type: "alert",
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err.response?.data == undefined) {
              this.openSenderIDError = this.$t("misc.network_error");
            }
            if (err.response && err.response.status === 404) {
              this.openSenderIDError = err.response.data.data.error;
            }
          });
      },
      cancelModal() {
        let editedData = store.getters["uistate/contactForm"];
        this.$emit("cancelMessageModal", editedData.isEdit);
      },
      editMessage() {
        this.loading = true;
        store
          .dispatch("campaigns/editScheduledMessages", this.formData)
          .then((response) => {
            this.loading = false;
            let payload = {
              data: response.data.data,
              key: this.selectedKey,
              message: response.data.message,
            };
            this.$emit("editTemplate", payload);
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.data == undefined) {
              this.errors = this.$t("misc.general_error");
            }
            if (err.response && err.response.status === 404) {
              this.errors = err.response.data.message;
            } else {
              this.$notify({
                title: this.$t("misc.toast.error"),
                message: err.response.data.message,
                type: "error",
              });
            }
          });
      },
      sendMessageTo(formName, type) {
        if (this.payloadData && this.payloadData.header) {
          this.formData.headers = this.payloadData.headers;
          this.formData.header = this.payloadData.header;
        }
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (type == "send") {
              this.$emit("sendSimpleData", this.formData);
            } else if (type === "schedule") {
              this.$emit("scheduleMessage", this.formData);
            } else {
              // make api request here
              this.editMessage();
            }
          } else {
            this.loading = false;
            return false;
          }
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .text_description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: #484a4f;
  }

  .___total_width {
    width: 100%;
  }

  .contains-custom-headers {
    overflow-x: auto;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .small-header-text {
    font-size: 12px;
    color: #f56c6c;
    text-align: center;
    position: relative;
    bottom: 0.5rem;
    left: 2rem;
  }

  .form-element * {
    height: 2rem;
  }

  .content_style {
    display: flex;
    justify-content: space-between;
    color: #f7921c;
    margin-bottom: 1em;
    font-size: 14px;

    div {
      cursor: pointer;
    }
  }

  .use-gsm {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info-gsm-only {
    position: relative;
    right: 0;
    font-size: 12px;
    color: #1ce1f7;
    margin-left: 1rem;
  }

  .check-gsm-only {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 30px;
  }

  .check-gsm-only * {
    margin: auto 0;
  }

  .info-gsm-button {
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info-icon {
    font-size: 25px;
    color: #0ca2d8;
  }

  .main-modal {
    background-color: #0000008f;
  }

  .modal-body {
    height: 25rem;
    overflow-y: scroll;
  }

  .form-container {
    width: 100%;
    padding-inline: 1rem;
    max-height: 70vh;
    overflow-y: scroll;
  }

  .cancel {
    width: fit-content;
  }
</style>

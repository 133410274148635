"use strict";

import apiService from "../../services/api.service";
import store from "../store";
import Storage from "@/services/storage";

/**
 * @typedef {Object} TranslationResponse
 * @property {boolean} success - Indicates if the translation was successful.
 * @property {string} data - The data object containing translation details.
 * @property {string} message - The message related to the translation.
 */

/**
 * @typedef {Object} VoiceOutput
 * @property {string} voice_url - The preview URL of the voice output.
 * @property {string} voice_template_id - The ID of the voice template.
 * @property {string} lang - The language of the voice output.
 */
const storage = new Storage();
export const state = {
  textOutput: "",
  voice_output: {
    preview_url: "",
    voice_template_id: "",
  },
  AIFeatureFlagUsersProd: [
    // PROD
    19950, 18447, 23252, 13275, 17566, 13087, 19667, 18537, 22327, 22338, 22338,
    23255, 177, 29870, 7002, 15598,
  ],
};

export const getters = {
  getTextOutput: (state) => state.textOutput,
  getVoiceOutput: (state) => state.voice_output,
  allowedAIFeatureFlagUsers: (state) => {
    const user = storage.getSavedState("currentUser");
    console.log(user);
    if (
      (location && location.href.includes("localhost")) ||
      location.href.includes("dev.mnotify.com")
    ) {
      return false;
    }
    return state.AIFeatureFlagUsersProd.includes(user.user_id);
  },
};

export const mutations = {
  SET_TEXT_OUTPUT(state, payload) {
    state.textOutput = payload;
  },
  RESET_TEXT_OUTPUT(state) {
    state.textOutput = "";
  },

  SET_VOICE_OUTPUT(state, payload) {
    state.voice_output = payload;
  },

  RESET_VOICE_OUTPUT(state) {
    state.voice_output = {
      preview_url: "",
      voice_template_id: "",
    };
  },
};

export const actions = {
  setTextOutput({ commit }, payload) {
    commit("SET_TEXT_OUTPUT", payload);
  },

  resetTextOutput({ commit }) {
    commit("RESET_TEXT_OUTPUT");
  },

  resetVoiceOutput({ commit }) {
    commit("RESET_VOICE_OUTPUT");
  },

  /**
   * Translates text using the provided payload.
   * @param {Object} context - The Vuex context object.
   * @param {Function} context.commit - The Vuex commit function.
   * @param {Object} payload - The payload for the translation request.
   * @param {string} payload.in - The text to be translated.
   * @param {string} payload.lang - The language pair for translation (e.g., "en-tw").
   * @returns {Promise<TranslationResponse>} The translation response.
   */
  async translateText({ commit }, payload) {
    try {
      const response = await apiService.post("api/translate-language", payload);
      /**
       * @type {TranslationResponse}
       */
      const data = response.data;
      // SET THE ORIGINAL TEXT
      commit("SET_TEXT_OUTPUT", data.data);
      return data;
    } catch (error) {
      return { success: false, data: {}, message: error.message };
    }
  },

  async generateTextToSpeech({ commit }, payload) {
    try {
      const response = await apiService.post("api/text-to-speech", payload);
      /**@type {VoiceOutput} */
      const data = response.data;
      const normalizedData = {
        campaign_name: payload.voice_note_title,
        audio: data.voice_url,
        voice_template_id: data.voice_template_id,
        voice_url: data.voice_url,
      };
      commit("SET_VOICE_OUTPUT", normalizedData);
      if (data.data) {
        const normalizedData = {
          campaign_name: payload.voice_note_title,
          audio: data.data.voice_url,
          voice_template_id: data.data.id,
          voice_url: data.data.voice_url,
        };
        commit("SET_VOICE_OUTPUT", normalizedData);
      }

      await store.dispatch("voice/getVoiceTemplates");

      return data;
    } catch (error) {
      // return { success: false, data: {}, message: error.message };
    }
  },
};

import apiService from "../../services/api.service";
import loginType from "@/helpers/loginType.js";
import Storage from "../../services/storage";
import axios from "axios";

const storage = new Storage();

export const state = {
  ballance: {},
  purchaseBundleHistory: [],
  depositBundleHistory: [],
  allHistory: [],
  debtAmount: [],
  transaction_id: "",
  toggleReceiptModal: false,
  sms_voice_rates_individual: [],
  sms_voice_rates_corporate: [],
};
export const mutations = {
  SET_BALANCE(state, payload) {
    state.ballance = payload;
  },
  SET_DEBT_AMOUNT(state, payload) {
    state.debtAmount = payload;
  },
  SET_PURCHASE_BUNDLE_HISTORY(state, payload) {
    state.purchaseBundleHistory = payload.sort(
      (a, b) => new Date(b.created_at) - new Date(a.create)
    );
    // Format Data Here
    state.purchaseBundleHistory = state.purchaseBundleHistory.forEach(
      (element) => {
        Object.keys(element).forEach((key) => {
          element.message_type = element.type === 1 ? "SMS" : "Voice";
        });
        return [].push(element);
      }
    );
  },
  UPDATE_PURCHASE_BUNDLE_HISTORY(state, payload) {
    let initialArray = [];
    initialArray.push(payload);
    // state.purchaseBundleHistory.push(payload);
    // Format Data Here
    initialArray = initialArray.forEach((element) => {
      Object.keys(element).forEach((key) => {
        element.message_type = element.type === 1 ? "SMS" : "Voice";
      });
      return [].push(element);
    });
    state.purchaseBundleHistory = initialArray;
  },
  //   MONEY PART
  SET_DEPOSIT_BUNDLE_HISTORY(state, payload) {
    state.depositBundleHistory = payload;
  },
  UPDATE_DEPOSIT_BUNDLE_HISTORY(state, payload) {
    state.depositBundleHistory
      .push(payload)
      .sort((a, b) => new Date(b.created_at) - new Date(a.create));
  },
  SET_ALL_HISTORY(state, payload) {
    let newHistory = [...payload.load_wallet, ...payload.bundle_history];
    state.allHistory = newHistory.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  },
  SET_TRANSACTION_ID(state, payload) {
    state.transaction_id = payload;
  },
  SET_TOGGLE_RECEIPT_MODAL(state, payload) {
    state.toggleReceiptModal = payload;
  },
  SET_SMS_VOICE_RATE(state, payload) {
    state.sms_voice_rates_individual = payload
      .filter((element) => {
        return (
          (element.user_type_rate == 1 || element.user_type_rate == 3) &&
          (loginType() == "sms"
            ? element.service_type == 1
            : element.service_type == 2)
        );
      })
      .sort((a, b) => {
        return a.amount - b.amount;
      });
    state.sms_voice_rates_corporate = payload
      .filter((element) => {
        return (
          (element.user_type_rate == 2 || element.user_type_rate == 3) &&
          (loginType() == "sms"
            ? element.service_type == 1
            : element.service_type == 2)
        );
      })
      .sort((a, b) => {
        return a.amount - b.amount;
      });
  },
};

export const actions = {
  depositByMtn({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/wallet/deposit`, payload)
        .then((response) => {
          if (response) {
            commit("UPDATE_PURCHASE_BUNDLE_HISTORY", response.data.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  depositHistory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/user/wallet/deposit/history`)
        .then((response) => {
          if (response) {
            commit("SET_DEPOSIT_BUNDLE_HISTORY", response.data.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  purchaseBundle({ commit }, payload) {
    payload.country = storage.getSavedState("currentUser").country || "GH";
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/sms/bundle/purchase`, payload)
        .then((response) => {
          if (response) {
            commit("UPDATE_PURCHASE_BUNDLE_HISTORY", response.data.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  walletBundleHistory({ commit }) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/wallet/bundle/history`)
        .then((response) => {
          if (response) {
            commit("SET_ALL_HISTORY", response.data.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  purchaseBundleHistory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/sms/bundle/purchase/history`, payload)
        .then((response) => {
          if (response) {
            commit("SET_PURCHASE_BUNDLE_HISTORY", response.data.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  accountBalance({ commit }) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/user/account/balance`)
        .then((response) => {
          if (response) {
            commit("SET_BALANCE", response.data.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  displayDebt({ commit }) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/user/debt/summary`)
        .then((response) => {
          if (response) {
            commit("SET_DEBT_AMOUNT", response.data.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  verifyStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/verify/wallet/status`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  verifyOTP({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/verify/otp/ `, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  // Export Wallet History
  exportWalletHistory({ commit }) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/export/wallet/history`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  // Export Bundle History
  exportBundleHistory({ commit }) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/export/bundle/history`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  checkPaymentStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/verify/transaction`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  checkPaystackStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .get(
          `api/verify/paystack/transaction?reference=${payload.reference}&phone=${payload.phone}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  checkPaytackChargeNumber: ({ commit }, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          `https://api.paystack.co/charge/${payload.reference}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_PAYSTACK_KEY}`,
            },
            timeout: 30000, // 30 seconds timeout
          }
        );

        if (
          response.data.status === true &&
          response.data.data?.authorization?.mobile_money_number
        ) {
          resolve(response.data.data.authorization.mobile_money_number);
          return response.data.data.authorization.mobile_money_number;
        } else {
          reject(
            new Error("Invalid response format or mobile number not found")
          );
        }
      } catch (error) {
        reject(
          error?.response?.data?.message || "Failed to fetch charge details"
        );
      }
    });
  },
  getPromoAmount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      payload.country = storage.getSavedState("currentUser").country || "GH";
      apiService
        .post(`api/get/custom/rate`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  generateReceipt({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/generate-receipt`, payload)
        .then((response) => {
          // download from response.data.data.path
          const link = document.createElement("a");
          link.href = response.data.path;
          link.target = "_blank"; // Add this to open in new tab
          link.rel = "noopener noreferrer"; // Security best practice for target="_blank"
          document.body.appendChild(link);
          link.click();
          link.remove();

          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  setTransactionID({ commit }, payload) {
    commit("SET_TRANSACTION_ID", payload);
  },
  setReceiptModal({ commit }, payload) {
    commit("SET_TOGGLE_RECEIPT_MODAL", payload);
  },
  getSMSVoiceRate({ commit }) {
    const userAuth = storage.getSavedState("currentUser");
    // check country if not ghana default to ghana
    if (!userAuth.country) {
      userAuth.country = "GH";
    }
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/get/bundle/rates?country=${userAuth.country || "GH"}`)
        .then((response) => {
          if (response) {
            commit("SET_SMS_VOICE_RATE", response.data.data);
            resolve(response);
          }
        })
        .catch((err) => reject(err));
    });
  },
  processFrancoPhonePayment({ commit }, payload) {
    if (!payload.email || !payload.amount || !payload.phone) {
      throw new Error("Please provide all required fields");
    }
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/paydunya/collection`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
};

export const getters = {
  ballance: (state) => state.ballance,
  purchaseBundleHistory: (state) => state.purchaseBundleHistory,
  depositBundleHistory: (state) => state.depositBundleHistory,
  allHistory: (state) => state.allHistory,
  debtAmount: (state) => state.debtAmount,
  getTransactionID: (state) => state.transaction_id,
  getReceiptModal: (state) => state.toggleReceiptModal,
  getSMSVoiceRatesIndividual: (state) => state.sms_voice_rates_individual,
  getSMSVoiceRatesCorporate: (state) => state.sms_voice_rates_corporate,
};

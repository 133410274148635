<template>
  <div
    class="modal fade"
    id="groupmodal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="groupmodal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <!--MODAL SUCCCES MESSAGE -->
      <div class="modal-content d-flex align-items-center" v-if="groupSuccess">
        <successMessage :message="groupSuccess" ref="groupSuccess" />
        <button @click="hideGroupModal" class="__close__success__modal">
          {{ $t("misc.button.close") }}
        </button>
      </div>
      <!-- CREATE NEW GROUP MODAL -->
      <div
        class="modal-content"
        v-if="!groupSuccess && groupFormType.mainModalType == 'new'"
      >
        <div
          class="modal-header pt-4 pb-2"
          v-if="groupFormType.type != 'delete'"
        >
          <h5 class="modal-title" id="exampleModalLabel">
            {{ groupFormType.title }}
          </h5>
        </div>
        <div class="modal-body">
          <div class="row justify-content-center">
            <div class="col-md-12" v-if="groupFormType.type != 'delete'">
              <el-form
                :model="groupFormData"
                :rules="groupRules"
                ref="ruleForm"
                @submit.prevent.native="checkEnter"
              >
                <div class="form_input_container">
                  <el-form-item
                    :label="$t('dashboard.contacts.excel.group_name')"
                    prop="name"
                  >
                    <el-input
                      type="text"
                      :placeholder="$t('dashboard.contacts.excel.placeholder')"
                      autocomplete="false"
                      v-model="groupFormData.name"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-form>
            </div>
            <div
              class="continue_edit_header pb-2"
              v-if="groupFormType.type == 'delete'"
            >
              <div class="_edit_icon">
                <img
                  src="../../../assets/Delete.svg"
                  height="33px"
                  width="31px"
                  alt="bin icon for delete"
                />
              </div>
              <h3 class="continue_edit_header_title">
                {{ $t("dashboard.contacts.excel.delete") }}
              </h3>
            </div>
            <div class="col-md-12">
              <Alert
                v-if="groupError"
                :message="groupError"
                :type="type"
                class="alert-type"
              />
            </div>
          </div>
          <div class="modal__footer__btns">
            <div v-if="groupFormType.type == 'delete'" class="deleteWrapper">
              <el-button
                v-if="groupFormType.type == 'delete'"
                id=""
                style="
                  margin-right: 1.5em;
                  padding-right: 2em;
                  padding-left: 2em;
                "
                :loading="groupLoading"
                type="danger"
                @click="deleteGroup"
              >
                {{ groupFormType.btnTitle }}
              </el-button>
              <el-button
                @click="hideGroupModal"
                type="primary"
                style="padding-right: 2em; padding-left: 2em"
                class="notranslate"
              >
                {{ $t("misc.button.cancel") }}
              </el-button>
            </div>
            <button
              v-if="groupFormType.type != 'delete'"
              class="cancel notranslate"
              @click="hideGroupModal"
            >
              {{ $t("misc.button.cancel") }}
            </button>
            <el-button
              id="__create__btn"
              v-if="groupFormType.type == 'new'"
              :loading="groupLoading"
              @click="createNewGroup('ruleForm')"
            >
              <img src="../../../assets/3 User.svg" alt="user icon" />
              {{ groupFormType.btnTitle }}
            </el-button>
            <el-button
              type="primary"
              v-if="groupFormType.type == 'edit'"
              :loading="groupLoading"
              @click="editGroup('ruleForm')"
            >
              {{ groupFormType.btnTitle }}
            </el-button>
          </div>
        </div>
      </div>
      <!-- SHOW EXCEL MODAL CONTENT -->
      <div
        class="modal-content"
        v-if="!groupSuccess && groupFormType.mainModalType === 'import'"
      >
        <div
          class="modal-header pt-4 pb-2 mb-5 modal__padding"
          style="margin-right: 0px !important; margin-left: 0px !important"
        >
          <h5 class="modal-title title-is-centered" id="exampleModalLabel">
            <img
              width="23px"
              src="../../../assets/vscode-icons_file-type-excel.svg"
              alt=""
            />
            &nbsp; {{ $t("dashboard.contacts.excel.import") }}
          </h5>
        </div>
        <div
          class="modal-body modal__padding"
          v-if="groupFormType.importExcelContentType == 'main'"
        >
          <div class="sample_excel table-responsive-md">
            <table class="table table-borderless example-table">
              <thead>
                <tr>
                  <th scope="col">A</th>
                  <th scope="col">B</th>
                  <th scope="col">C</th>
                  <th scope="col">D</th>
                  <th scope="col">E</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" class="excel-header-example">firstname</th>
                  <td class="excel-header-example">lastname</td>
                  <td class="excel-header-example">phone</td>
                  <td class="excel-header-example">email</td>
                  <td class="excel-header-example">date_of_birth</td>
                </tr>
                <tr>
                  <th scope="row">George</th>
                  <td>Darko</td>
                  <td>0202346754</td>
                  <td>darko@mail.com</td>
                  <td>19/09/98</td>
                </tr>
                <tr class="tr_stripped">
                  <th scope="row">George</th>
                  <td>Darko</td>
                  <td>0202346754</td>
                  <td>darko@mail.com</td>
                  <td>19/09/98</td>
                </tr>
                <tr>
                  <th scope="row">George</th>
                  <td>Darko</td>
                  <td>0202346754</td>
                  <td>darko@mail.com</td>
                  <td>19/09/98</td>
                </tr>
                <tr class="tr_stripped">
                  <th scope="row">George</th>
                  <td>Darko</td>
                  <td>0202346754</td>
                  <td>darko@mail.com</td>
                  <td>19/09/98</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="main_text">
            <h4 class="uppercase">
              <strong class="note-excel">{{
                $t("dashboard.send_message.sms.excel_sms.note.please_note")
              }}</strong>
            </h4>
            <p>
              {{ $t("dashboard.contacts.excel.note.header") }}
              <span class="required-header-names notranslate"
                ><br />
                firstname, lastname, phone, email, date_of_birth.</span
              >
            </p>
            <h5 style="font-weight: bold">
              {{ $t("dashboard.send_message.sms.excel_sms.note.max") }}
              <span class="note-excel"> 5MB </span>
            </h5>

            <!-- <p>To insert the content of a column into your message, select the corresponding column header.
                            Eg: [firstname], [lastname], [date_of_birth].</p> -->
          </div>
          <div class="text-center">
            <a :href="importExcel" download="" class="__cancel">{{
              $t("dashboard.contacts.excel.note.download")
            }}</a>
          </div>
          <div class="modal__footer__btns">
            <button class="cancel notranslate" @click="hideGroupModal">
              {{ $t("misc.button.cancel") }}
            </button>
            <el-button id="__large__btn" type="primary" @click="selectGroup"
              ><img src="../../../assets/3 User.svg" alt="" /> &nbsp;
              {{ $t("dashboard.contacts.excel.import") }}
            </el-button>
          </div>
        </div>
        <!-- SELECT GROUP TO UPLOAD EXCEL -->
        <div
          class=""
          v-if="groupFormType.importExcelContentType === 'selectgroup'"
        >
          <div class="modal-body modal__padding">
            <div class="__select__group__main">
              <!-- SHOW THIS CONTENT WHEN THERE IS NO GROUP AVAILABLE -- -->
              <div class="_select_group_inner">
                <div
                  class="__show__no__group__message text-center"
                  v-if="
                    Array.isArray(fetchedGroups) && fetchedGroups.length == 0
                  "
                >
                  <h3>{{ $t("dashboard.contacts.excel.no_groups") }}</h3>
                  <el-button @click="addNewGroup" type="primary">
                    {{ $t("dashboard.groups.title") }}
                  </el-button>
                </div>
                <div
                  class="row"
                  style="width: 100%"
                  v-if="
                    Array.isArray(fetchedGroups) && fetchedGroups.length > 0
                  "
                >
                  <div class="col-md-12">
                    <el-form
                      :model="importContactForm"
                      ref="ruleform"
                      @submit.prevent.native="checkEnter"
                    >
                      <div class="form_input_container">
                        <el-form-item
                          :label="$t('dashboard.groups.select_group_excel')"
                          prop="group_id"
                          :rules="[
                            {
                              required: true,
                              message: 'Please enter group ID',
                              trigger: ['blur', 'change'],
                            },
                          ]"
                          v-loading="fetchingGroup"
                        >
                          <el-select
                            style="width: 100%"
                            v-model="importContactForm.group_id"
                            filterable
                            :placeholder="$t('dashboard.groups.choose')"
                            class="is-el-select"
                            @focus="initDataFetch"
                            v-loading="fetchingGroup"
                          >
                            <div
                              class="infinite-scroll"
                              v-infinite-scroll="useInfiniteLoading"
                            >
                              <el-option
                                v-for="(group, key) in groups"
                                :key="key"
                                :label="`${group.name}`"
                                :value="group.id"
                              >
                              </el-option>
                              <p
                                class="loading-state"
                                v-if="loadingGroupMembers"
                              >
                                Loading...
                              </p>
                              <p
                                class="loading-state"
                                v-if="
                                  groups.length > 0 &&
                                  groups.length == totalGroupMembers
                                "
                              >
                                {{
                                  $t(
                                    "dashboard.send_message.sms.group_sms.all_loaded"
                                  )
                                }}
                              </p>
                            </div>
                            <div
                              style="
                                display: flex;
                                width: 100%;
                                margin: 1rem auto;
                                justify-content: center;
                              "
                            >
                              <el-button @click="addNewGroup" type="primary">
                                {{ $t("dashboard.groups.title") }}
                              </el-button>
                            </div>
                          </el-select>
                        </el-form-item>
                      </div>
                    </el-form>
                  </div>
                </div>
                <!-- <button class="__create_new_group" @click="addNewGroup">Create new  group</button> -->
                <div class="modal__footer__btns px-3" style="width: 100%">
                  <button class="cancel notranslate" @click="hideGroupModal">
                    {{ $t("misc.button.cancel") }}
                  </button>
                  <el-button
                    style="padding-right: 3em; color: #fff; padding-left: 3em"
                    v-if="
                      Array.isArray(fetchedGroups) && fetchedGroups.length > 0
                    "
                    type="primary"
                    @click="nextToimportGroup('ruleform')"
                    >{{ $t("misc.button.next") }}</el-button
                  >
                </div>
              </div>
              <!-- SELECT GROUPS  -->
            </div>
          </div>
        </div>
        <!-- EXCEL UPLOADER -->
        <div
          class="modal-body d-flex flex-column align-items-center justify-content-center el-upload-centered"
          v-if="groupFormType.importExcelContentType == 'excelimport'"
        >
          <el-upload
            class="avatar-uploader"
            style="
              cursor: pointer;
              position: relative;
              overflow: hidden;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
            action="#"
            :show-file-list="true"
            :file-list="fileList"
            :auto-upload="false"
            accept=".xlsx, .xls, .csv"
            :multiple="false"
            drag
            :on-change="handleChange"
          >
            <div class="el-upload__text">
              <p class="__upload__audio__file">
                {{ $t("dashboard.send_message.sms.excel_sms.import") }}
              </p>
              <p class="file_type">
                {{ $t("dashboard.send_message.sms.excel_sms.type") }}
              </p>
              <img
                src="../../../assets/vscode-icons_file-type-excel.svg"
                alt="excel icon"
              />
              <p class="drag_file">
                {{ $t("dashboard.send_message.sms.excel_sms.drag_or") }}
                <em>
                  {{ $t("dashboard.send_message.sms.excel_sms.browse") }}</em
                >
              </p>
            </div>
          </el-upload>
          <div class="col-md-12 mt-2" v-if="fileError">
            <Alert
              :message="fileError"
              :type="'alert-danger'"
              class="alert-type"
            />
          </div>
          <div class="col-md-12 mt-2" v-if="errors.length">
            <Alert
              v-for="(error, key) in errors"
              :key="key"
              :message="error"
              :type="'alert-danger'"
              class="alert-type"
            />
          </div>
          <div
            style="width: 100% !important"
            class="d-flex my-5 justify-content-between"
          >
            <button class="cancel" @click="backToSelectGroup">
              {{ $t("misc.button.back") }}
            </button>
            <el-button
              :loading="groupLoading"
              @click="importContacts"
              style="height: 43px"
              v-if="fileUploadSuccess"
              type="primary"
            >
              {{ $t("dashboard.contacts.excel.note.import") }}
            </el-button>
          </div>
        </div>
        <!--   :limit="1"
                        :on-exceed="handleFileExceed" -->
      </div>
    </div>
  </div>
</template>
<script>
  import store from "@/state/store.js";
  let $ = require("jquery");
  import { read, utils } from "xlsx";
  export default {
    name: "import-contact-from-excel",
    props: ["edit"],
    data() {
      return {
        selectGroupForm: {
          group_id: "",
        },
        selectgrouprules: {
          group_id: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
        },
        fileList: [],
        groupLoading: false,
        groupError: "",
        type: "alert-danger",
        groupSuccess: "",
        edittingGroup: {},
        importExcelContentType: "",
        fileUploadSuccess: false,
        fileList: [],
        fileError: "",
        errors: "",
        // groupFormType:{
        //     title:"Create new group",
        //     btnTitle:"Create new group",
        //     type:'new'

        // },
        groupFormData: {
          name: "",
          group_id: "",
          key: "",
        },
        importContactForm: {
          group_id: "",
          import_contact_csv: "",
        },
        importContactRules: {
          group_id: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
        },
        groupRules: {
          import_contact_csv: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
        },
        groups: [],
        page: 1,
        loadingGroupMembers: false,
        totalGroupMembers: 0,
        success: "rest",
        fetchingGroup: false,
      };
    },
    updated() {
      if (this.edit) {
        this.groupFormData = this.edit;
      }
    },
    computed: {
      importExcel() {
        return process.env.VUE_APP_ENGINE_URL + "/storage/contacts_import.xlsx";
      },
      fetchedGroups() {
        return store.getters["groups/all_groups"];
      },
      fetchingData() {
        return store.getters["groups/fetchingData"];
      },
      groupFormType() {
        return store.getters["uistate/groupFormData"];
      },
    },
    methods: {
      downloadExcel() {
        const url = URL.createObjectURL(
          "https://prodapi.mnotify.com/storage/contacts_import.xlsx"
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `sample_excel.xlsx`);
        document.body.appendChild(link);
        link.click();
      },
      backToSelectGroup() {
        this.selectGroup();
      },
      importContacts() {
        this.groupSuccess = "";
        this.groupError = "";
        this.groupLoading = "";
        const formData = new FormData();
        formData.append("group_id", this.importContactForm.group_id);
        formData.append("import_contact_csv", this.fileList[0]);
        store
          .dispatch("contacts/importContact", formData)
          .then((response) => {
            this.$alert(response.data.message, this.$t("misc.toast.success"), {
              type: "success",
              closeOnClickModal: true,
            });
            if (Array.isArray(response.data)) {
              this.errors = response.data[0].errors;
            } else {
              this.$emit("addContactSuccess");
              this.$emit("cancel");
            }
          })
          .catch((err) => {
            if (err.response?.data == undefined) {
              this.groupError = [this.$t("misc.network_error")];
              this.errors = [his.$t("misc.network_error")];
            }
            if (err.response.status === 404) {
              this.groupError = [err.response.data.message];
              this.errors = [err.response.data.message];
            }
          })
          .finally(() => {
            this.groupLoading = false;
          });
      },
      handleFileExceed() {
        this.$message.error(
          this.$t("dashboard.send_message.sms.excel_sms.note.min_file")
        );
      },
      // handleChange(file, fileList){
      //     this.fileList = [file.raw]
      //     this.fileUploadSuccess = true
      // },
      async handleChange(file, fileList) {
        this.fileList = [file.raw];
        this.fileError = "";
        this.errors = "";

        const f = await file.raw.arrayBuffer();
        const wb = read(f);
        const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
          raw: false,
        });
        // let parsedData = await xlsxParser.onFileSelection(await file.raw, { showNullProperties: false, hideEmptyRows: true })
        // Use new parser
        let excelSheet = data; //parsedData.Sheet1
        if (!excelSheet.length) {
          this.fileError = this.$t(
            "dashboard.send_message.sms.excel_sms.note.min_two"
          );
          return;
        }
        //  let headers = Object.keys(excelSheet[0])
        // // let expectedHeaders = ['firstname', 'lastname','phone','email','date_of_birth']
        // if(headers !== expectedHeaders) {
        //     this.fileError = `Headers should be ['firstname', 'lastname','phone','email','date_of_birth'] format.`
        // }
        this.fileUploadSuccess = true;
      },
      handleError(err, file, fileLis) {
        this.$message.error(this.$t("misc.general_error"));
      },
      handleSuccess(file) {
        this.groupSuccess = this.$t("dashboard.groups.group_success");
      },
      nextToimportGroup(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            store.dispatch("uistate/addGroupFormData", {
              title: "",
              mainModalType: "import",
              type: "import",
              btnTitle: this.$t("dashboard.contacts.import_excel_sheet"),
              importExcelContentType: "excelimport",
            });
          } else {
            return false;
          }
        });
      },
      addNewGroup(type) {
        store.dispatch("uistate/addGroupFormData", {
          title: this.$t("dashboard.groups.create_new"),
          mainModalType: "new",
          type: "new",
          btnTitle: this.$t("dashboard.groups.create_new"),
          from: "from_select_contacts",
        });
      },
      selectGroup() {
        this.importContactForm.group_id = "";
        store.dispatch("uistate/addGroupFormData", {
          title: "",
          mainModalType: "import",
          type: "import",
          btnTitle: this.$t("dashboard.contacts.import_excel_sheet"),
          importExcelContentType: "selectgroup",
        });
      },
      resetGroupState() {
        this.groupFormData.name = null;
        this.groupSuccess = "";
        (this.groupError = ""), (this.groupLoading = false);
        this.fileList = [];
        this.fileError = "";
        this.selectGroupForm = {
          group_id: "",
        };
      },
      hideGroupModal() {
        this.resetGroupState();
        this.$emit("cancel");
      },
      // API ACTIONS
      deleteGroup() {
        this.groupError = "";
        this.groupLoading = true;
        store
          .dispatch("groups/deleteGroup", this.groupFormData)
          .then((response) => {
            this.groupSuccess = response.data.message;
            this.groupLoading = false;
            this.$emit("RefreshComponentFromExcel");
          })
          .catch((err) => {
            this.groupLoading = false;
            if (err.response?.data == undefined) {
              this.groupError = this.$t("misc.network_error");
            }
            if (err.response && err.response.status === 404) {
              this.groupError = err.response.data.message;
            }
          })
          .finally(() => {
            this.$emit("addContactSuccess");
          });
      },
      editGroup(formName) {
        this.groupError = "";
        this.groupLoading = true;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            store
              .dispatch("groups/updateGroup", this.groupFormData)
              .then((response) => {
                this.groupSuccess = response.data.message;
                this.groupLoading = false;
                this.groupFormData.name = "";
                this.$emit("RefreshComponentFromExcel");
              })
              .catch((err) => {
                this.groupLoading = false;

                if (err.response?.data == undefined) {
                  this.groupError = this.$t("misc.network_error");
                }
                if (err.response && err.response.status === 404) {
                  this.groupError = err.response.data.message;
                }
              })
              .finally(() => {
                this.$emit("addContactSuccess");
              });
          } else {
            this.groupLoading = false;
            return false;
          }
        });
      },
      createNewGroup(formName) {
        // find create new group
        this.groupError = "";
        this.groupLoading = true;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            store
              .dispatch("groups/createGroup", this.groupFormData)
              .then((response) => {
                this.groupLoading = false;
                this.groupFormData.name = null;
                let groupForm = this.groupFormType;
                if (groupForm && groupForm.from == "from_select_contacts") {
                  return this.selectGroup();
                }
                this.groupSuccess = response.data.message;
              })
              .catch((err) => {
                this.groupLoading = false;
                if (err.response?.data == undefined) {
                  this.groupError = this.$t("misc.network_error");
                }
                if (err.response && err.response.status === 404) {
                  this.groupError = err.response.data.message;
                }
              })
              .finally(() => {
                this.$emit("addContactSuccess");
              });
          } else {
            this.groupLoading = false;
            return false;
          }
        });
      },
      useInfiniteLoading() {
        if (this.loadingGroupMembers) {
          return;
        }

        this.loadingGroupMembers = true;
        if (this.groups.length === this.totalGroupMembers) {
          this.loadingGroupMembers = false;
          return;
        }

        // Increment this.page only if there is more data to load
        if (this.groups.length < this.totalGroupMembers) {
          store
            .dispatch("groups/listGroups", this.page)
            .then((response) => {
              let data = response.data.data.data;

              // Filter out duplicates before appending
              data = data.filter(
                (group) =>
                  !this.groups.find(
                    (existingGroup) => existingGroup.id === group.id
                  )
              );

              if (data.length > 0) {
                // Append filtered data to this.groups
                this.groups = [...this.groups, ...data];
              }

              // Increment this.page here
              this.page = response.data.data.current_page + 1;
            })
            .finally(() => {
              this.loadingGroupMembers = false;
            });
        }
      },
      initDataFetch() {
        if (this.groups.length > 0) {
          return;
        }
        this.fetchingGroup = true;
        store
          .dispatch("groups/listGroups", this.page)
          .then((response) => {
            this.fetchingGroup = false;
            let data = response.data.data.data;
            this.totalGroupMembers = response.data.data.total;
            this.groups = data;
            this.page = 1;
          })
          .catch((err) => (this.fetchingGroup = false));
      },
    },

    mounted() {
      // if group success is active and user clicks outside the modal close it
      document.addEventListener("click", (e) => {
        if (
          this.groupSuccess &&
          !this.$refs.groupSuccess.$el.contains(e.target)
        ) {
          this.hideGroupModal();
        }
      });
    },
  };
</script>

<style scoped>
  @import url("../../../styles/__importexcel.scss");

  .__group__action_btn {
    border: none !important;
    outline: none !important;
    background-color: transparent !important;
  }

  .__edit {
    width: 20.06px !important;
    height: 19.82px !important;
  }

  .mnotify-card-header {
    padding: 1em 0.5em;
  }

  .__delete {
    width: 18.05px;
    height: 22.99;
  }

  ._select_group_inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .upload__text {
    padding: 4em;
  }

  .__lg_modal__footer {
    padding-right: 7em;
    padding-left: 7em;
  }

  ._select_group_inner h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #051c50;
  }

  ._select_group_inner p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    color: #484a4f;
    opacity: 0.5;
  }

  .__create_new_group {
    width: 149.06px;
    height: 40px;
    background: #f3f3f4;
    border-radius: 5px;
    font-style: normal;
    font-weight: 550;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #f7921c;
    border: none;
    outline: none;
  }

  ._create_a_new_groud {
    width: 100%;
    background: #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .el-upload__text h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #575761;
    padding-top: 1em;
  }

  .el-upload__text p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #575761;
  }

  @media screen and (min-width: 998px) and (max-width: 1300px) {
    #new__group__btn {
      width: 70% !important;
      font-size: 0.7em;
      text-align: center;
      display: flex;
      align-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 998px) {
    .__main__group {
      margin-top: 20px !important;
    }
  }

  /*  */
  .title-is-centered {
    text-align: center;
    margin: 0 auto;
    padding: 0;
    width: 100%;
  }

  .el-upload-centered > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .example-table {
    margin-top: -2rem;
  }

  .example-table * {
    padding: 0.2rem;
  }

  .modal__footer__btns {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .alert-type {
    text-align: center;
  }

  .note-excel {
    color: red;
  }

  .excel-header-example {
    background-color: #1c6c40;
    color: white;
    font-weight: 600;
  }

  .required-header-names {
    color: hsl(0, 100%, 70%);
    font-weight: 600;
  }

  .infinite-scroll {
    height: 250px;
    padding-top: 1rem;
    overflow-y: scroll;
  }

  .loading-state {
    text-align: center;
    font-size: small;
    padding-top: 10px;
  }
</style>
